import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import initApiClient from 'services/ApiClient';

import logSentryError from 'utils/sentry';

import type { PriceExperiments, PriceExperimentsState } from './types';

export const fetchPriceExperimentsVariants = createAsyncThunk<PriceExperiments>(
  'priceExperiments/fetchPriceExperimentsVariants',
  async (_, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const query = await APIClient.get('/v1/backoffice/price_experiment_variants/');
      const priceExperiments = await query.json();
      return priceExperiments;
    } catch (err) {
      logSentryError('[fetchPriceExperimentsVariants]', err);
      throw err;
    }
  }
);

const initialState: PriceExperimentsState = {
  status: 'idle',
  error: null,
  list: [],
};

const priceExperimentsSlice = createSlice({
  name: 'priceExperiments',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchPriceExperimentsVariants.pending, draftState => {
        draftState.status = 'pending';
        draftState.error = null;
      })
      .addCase(fetchPriceExperimentsVariants.fulfilled, (draftState, action) => {
        const priceExperiments = action.payload;

        draftState.status = 'fulfilled';
        draftState.list = priceExperiments;
      })
      .addCase(fetchPriceExperimentsVariants.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      });
  },
});

const { reducer } = priceExperimentsSlice;

export default reducer;
