import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';

import includes from 'lodash/fp/includes';
import _ from 'lodash/fp/placeholder';
import some from 'lodash/fp/some';

import * as AuthService from 'services/Auth';

import logSentryError from 'utils/sentry';

import { signout } from 'dux/auth/slice';

import getEnv from './getEnv';

const isAuthErrorMessage = errorString =>
  some(includes(_, errorString), ['Cannot refresh token, must signin', 'Unauthenticated']);

const authBaseQuery = fetchBaseQuery({
  baseUrl: getEnv('REACT_APP_ROOT_URL'),
  prepareHeaders: (headers /* , api */) => {
    try {
      const { accessToken } = AuthService.getAuth(); // this is the source of truth for now
      if (accessToken) headers.set('authorization', `Bearer ${accessToken}`);
    } catch (err) {
      logSentryError('[authBaseQuery] error', err);
    }
    return headers;
  },
});

const customBaseQuery = async (args, api, extraOptions) => {
  try {
    let result = await authBaseQuery(args, api, extraOptions);

    // If it failed with a 401 try naively to refresh the token without extra checks
    if (result.error?.status === 401) {
      try {
        await AuthService.refresh();
        result = await authBaseQuery(args, api, extraOptions);
      } catch (error) {
        const errorString = error?.toString() ?? error?.message;
        if (isAuthErrorMessage(errorString)) {
          api.dispatch(signout()); // use redux action to delete the local storage + redirect to login
        }
        throw error;
      }
    }
    return result;
  } catch (error) {
    const errorString = error?.toString() ?? error?.message;
    if (isAuthErrorMessage(errorString)) return { error }; // end here and do not log to Sentry if it's an auth error

    const url = args.url ?? args;
    logSentryError(() => `[customBaseQuery] (url: ${url}) error: ${errorString}`, error);
    return { error };
  }
};

export default customBaseQuery;
