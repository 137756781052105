import eq from 'lodash/fp/eq';
import get from 'lodash/fp/get';
import startsWith from 'lodash/fp/startsWith';

import getEnv from './getEnv';

export const isStaging = startsWith('staging');
export const isPreprodTest = eq('preprod-tests-backoffice');
const isPreprod = eq('preprod');
export const isDevMode = eq('development');
export const isTestMode = eq('test');
export const isProduction = eq('production');
export const getReactAppEnv = get('REACT_APP_ENV', getEnv());

export const isInTestEnvFromProcessEnv = envVars => {
  // MODE dictates the dev server / build from which file to populate the envVars
  const mode = get('MODE', envVars);
  const reactAppEnv = get('REACT_APP_ENV', envVars);
  return (
    // First and foremost, ensure REACT_APP_ENV is not production
    !isProduction(reactAppEnv) &&
    // Then check for possible test env scenarios with REACT_APP_ENV
    (isStaging(reactAppEnv) ||
      isPreprodTest(reactAppEnv) ||
      isPreprod(reactAppEnv) ||
      // Also check possible test mode scenarios with MODE
      isDevMode(mode) ||
      isTestMode(mode))
  );
};
export default () => isInTestEnvFromProcessEnv(getEnv());
