import isBoolean from 'lodash/fp/isBoolean';
import isString from 'lodash/fp/isString';

import stringToBoolOrValue from 'utils/envParsing';
import getEnv from 'utils/getEnv';

import type { FeatureFlagState } from './types';

export const activeOrVariantStructureFromEnv = envVariable => {
  const value = stringToBoolOrValue(envVariable);
  if (isString(value)) {
    return { active: true, variant: value };
  }
  if (isBoolean(value)) {
    return { active: value };
  }
  return {};
};

export const initialState: FeatureFlagState = {
  status: 'idle',
  // Flags as we receive them from the back-end.
  flags: {
    'survey-updates-phase-1': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_SURVEY_UPDATES_PHASE_1')
    ),

    factory: activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_FACTORY')),

    'buffer-bin-complete-message': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_BUFFER_BIN_COMPLETE_MESSAGE')
    ),

    'brush-consolidation': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_BRUSH_CONSOLIDATION')
    ),

    'multi-lane-improvements': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_MULTI_LANE_IMPROVEMENTS')
    ),

    'short-polling-download-pdf-documents': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_SHORT_POLLING_DOWNLOAD_PDF_DOCUMENTS')
    ),

    'accessory-gwp': activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_ACCESSORY_GWP')),

    'microhold-palletization': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_MICROHOLD_PALLETIZATION')
    ),

    'dispatch-modal-box-status': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_DISPATCH_MODAL_BOX_STATUS')
    ),

    'production-list-tab-refactor': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_PRODUCTION_LIST_TAB_REFACTOR')
    ),

    selfies: activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_SELFIES')),
    'selfies-error-detection': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_SELFIES_ERROR_DETECTION')
    ),
    'data-only-ui': activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_DATA_ONLY_UI')),
    'pop-up-country': activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_POP_UP_COUNTRY')),
  },
};
