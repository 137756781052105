import { createApi } from '@reduxjs/toolkit/query/react';

import customBaseQuery from 'utils/customBaseQuery';

import type { UseQueryResponse } from './types';

const dataportalApi = createApi({
  reducerPath: 'dataportal',
  baseQuery: customBaseQuery, // basic example uses fetchBaseQuery
  tagTypes: ['DataApp'],
  // --------------- ENDPOINTS --------------------------------------------------------------
  endpoints: builder => ({
    getDataApps: builder.query<UseQueryResponse, void>({
      query: () => ({ url: `/v1/backoffice/dataapps/`, method: 'get' }),
      providesTags: [{ type: 'DataApp', id: 'LIST' }],
      keepUnusedDataFor: 0, // only because we need to invalidate for e2e (to be removed when e2e is updated with more than one route)
    }),
  }),
});

export default dataportalApi;

export const { getDataApps } = dataportalApi.endpoints;
