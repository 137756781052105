export const ADMIN = 'admin';
export const AUTOMATION_SCREENS = 'automationScreens';
export const BUFFER_SPACE = 'buffer-space';
export const BUFFERED = 'buffered';
export const LABELING = 'labeling';
export const EOL_BUFFER_SPACE = 'endoflanebufferspace';
export const LAB = 'lab';
export const PACKING = 'packing';
export const PACKING_STATION = 'packing';
export const PREPACK = 'prepack';
export const PROCESSING = 'processing';
export const PRODUCTION_LAB_AUTOMATION = 'production-lab-automation';
export const QUALITY_TESTS = 'qualitytests';
export const PALLETS_MANAGEMENT = 'palletsmanagement';
export const PALLETIZE_BOXES = 'palletizeboxes';
